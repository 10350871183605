/* eslint-disable no-unused-vars */
import SecureLS from "secure-ls";
import router from "@/router/index";
import { of } from "rxjs";
import { map } from "rxjs/operators";
import ApiServices from "@/services/ApiServices";
import Router from "../../router/index";
const ls = new SecureLS({
  isCompression: false
});

const state = {
  user: {
    token: "",
    userType: "",
    refreshToken: "",
    details: {
      id: "",
      name: "",
      phone: "",
      organizationName: ""
    }
  }
};
const mutations = {
  UPDATE_TOKEN(state, payload) {
    state.user.token = payload?.data.accessToken;
    state.user.refreshToken = payload?.data?.refreshToken;
  },
  UPDATE_USER_DETAILS(state, payload) {
    state.user.details = payload;
  }
};
const actions = {
  async logIn({ commit, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response$ = of(await ApiServices.auth(payload)).pipe(
        map(response => response.data)
      );
      response$.subscribe(data => {
        const {
          user: { name, phone }
        } = data.data;
        commit("UPDATE_TOKEN", data);
        commit("UPDATE_USER_DETAILS", { name, phone });
        dispatch("isLoading", false, { root: true });
        Router.push({ name: "admin.home.dashboard" }).catch(() => {});
      });
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred while authenticating user",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async me({ commit, dispatch }) {
    try {
      dispatch("isLoading", true, { root: true });
      const response$ = of(await ApiServices.me());
      response$.subscribe(({ data }) => {
        dispatch("isLoading", false, { root: true });
        const { name, phone, organizationId, _id } = data?.data;
        commit("UPDATE_USER_DETAILS", {
          id: _id,
          name,
          phone,
          organizationName: organizationId?.name
        });
      });
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred while getting profile details",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async logOut({ commit, dispatch }, payload) {
    ls.removeAll();
    commit("UPDATE_TOKEN", payload);
    await router.replace({
      name: "account.login"
    });
  }
};

const getters = {
  isAuthenticated: state => !!state.user.token,
  getToken: state => state.user.token,
  getUserDetails: state => state.user.details,
  getUserType: state => state.user.userType
};

export const auth = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
