import ApiServices from "@/services/ApiServices";
import { map, of } from "rxjs";

const state = {
  list: [],
  paginate: {
    total: 0,
    size: 30,
    page: 1
  }
};

const mutations = {
  UPDATE_BALANCES(state, payload) {
    state.list = payload;
  },
  UPDATE_PAGINATION(state, payload) {
    state.paginate = payload;
  }
};

const actions = {
  async list({ commit, dispatch }, query) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response$ = of(await ApiServices.listAllBalances(query)).pipe(
        map(response => response.data)
      );
      response$.subscribe(balance => {
        dispatch("isPageLoading", false, { root: true });
        const { docs, totalDocs, limit, page } = balance.data;
        commit("UPDATE_BALANCES", docs);
        commit("UPDATE_PAGINATION", { total: totalDocs, size: limit, page });
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred getting list of transactions",
          status: "red"
        },
        { root: true }
      );
      dispatch("isPageLoading", false, { root: true });
    }
  },
  async uploadBalances({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const formData = new FormData();
      formData.append("file", payload);
      const response$ = of(await ApiServices.uploadBalances(formData)).pipe(
        map(response => response.data)
      );
      response$.subscribe(() => {
        dispatch("isLoading", false, { root: true });
        dispatch("list", "?page=1&size=30");
        dispatch("updateDialog", { idx: "add", status: false }, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: `Balances uploaded successfully`,
            status: "success"
          },
          { root: true }
        );
      });
    } catch (e) {
      console.log(e);
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred getting list of transactions",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async searchList({ commit, dispatch }, query) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response$ = of(await ApiServices.searchAllBalances(query)).pipe(
        map(response => response.data)
      );
      response$.subscribe(members => {
        dispatch("isPageLoading", false, { root: true });
        const { docs, totalDocs, limit, page } = members.data;
        commit("UPDATE_BALANCES", docs);
        commit("UPDATE_PAGINATION", { total: totalDocs, size: limit, page });
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred while search balances",
          status: "red"
        },
        { root: true }
      );
      dispatch("isPageLoading", false, { root: true });
    }
  }
};

const getters = {
  getAllBalances: state => state.list,
  getPagination: state => state.paginate
};

export const balances = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
