import ApiServices from "@/services/ApiServices";
import { of } from "rxjs";
import { map } from "rxjs/operators";
import Router from "../../router/index";

const state = {
  list: [],
  details: {},
  paginate: {
    total: 0,
    page: 1,
    size: 30
  }
};

const mutations = {
  UPDATE_LIST(state, payload) {
    state.list = payload;
  },
  UPDATE_PAGINATION(state, payload) {
    state.paginate = payload;
  },
  UPDATE_DETAILS(state, payload) {
    state.details = payload;
  }
};
const actions = {
  async list({ commit, dispatch }, query) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response$ = of(await ApiServices.listAllSchools(query)).pipe(
        map(response => response.data)
      );
      response$.subscribe(members => {
        console.log(members);
        dispatch("isPageLoading", false, { root: true });
        const { docs, totalDocs, limit, page } = members.data;
        commit("UPDATE_LIST", docs);
        commit("UPDATE_PAGINATION", { total: totalDocs, size: limit, page });
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred while authenticating user",
          status: "red"
        },
        { root: true }
      );
      dispatch("isPageLoading", false, { root: true });
    }
  },
  async listAllStudents({ commit, dispatch }, payload) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const { id, query } = payload;
      const response$ = of(await ApiServices.listAllStudents(id, query)).pipe(
        map(response => response.data)
      );
      response$.subscribe(members => {
        dispatch("isPageLoading", false, { root: true });
        const { docs, totalDocs, limit, page } = members.data;
        commit("UPDATE_LIST", docs);
        commit("UPDATE_PAGINATION", { total: totalDocs, size: limit, page });
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred while authenticating user",
          status: "red"
        },
        { root: true }
      );
      dispatch("isPageLoading", false, { root: true });
    }
  },
  async create({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response$ = of(
        await ApiServices.addNewMembersFroAdmin(payload)
      ).pipe(map(response => response.data));
      response$.subscribe(member => {
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: member?.data?.message ?? `New member created successfully`,

            status: "success"
          },
          { root: true }
        );
        setTimeout(() => {
          Router.push({ name: "admin.customers.all" });
        }, 2000);
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred while authenticating user",
          status: "red"
        },
        { root: true }
      );
      dispatch("isLoading", false, { root: true });
    }
  },
  async update({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const { _id, ...rest } = payload;
      const response$ = of(
        await ApiServices.updateCustomerForAdmin(_id, rest)
      ).pipe(map(response => response.data));
      response$.subscribe(member => {
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message:
              member?.data?.message ?? `Member details updated successfully`,

            status: "success"
          },
          { root: true }
        );
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred while updating user information",
          status: "red"
        },
        { root: true }
      );
      dispatch("isLoading", false, { root: true });
    }
  },
  async delete({ dispatch }, id) {
    try {
      dispatch("isLoading", true, { root: true });
      const response$ = of(await ApiServices.deleteCustomerForAdmin(id)).pipe(
        map(response => response.data)
      );
      response$.subscribe(staffs => {
        dispatch("isLoading", false, { root: true });
        dispatch("list", `?page=1&size=30`);
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message:
              staffs.data?.message ?? "Member details successfully deleted",
            status: "success"
          },
          { root: true }
        );
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred while deleting member",
          status: "red"
        },
        { root: true }
      );
      dispatch("isLoading", false, { root: true });
    }
  },
  async restore({ dispatch }, id) {
    try {
      dispatch("isLoading", true, { root: true });
      const response$ = of(
        await ApiServices.restoreDeletedCustomerForAdmin(id)
      ).pipe(map(response => response.data));
      response$.subscribe(staffs => {
        dispatch("isLoading", false, { root: true });
        dispatch("listAllMembersWithTrash", `?page=1&size=30`);
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message:
              staffs.data?.message ?? "Member details successfully restored",
            status: "success"
          },
          { root: true }
        );
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred while restoring staff member",
          status: "red"
        },
        { root: true }
      );
      dispatch("isLoading", false, { root: true });
    }
  },
  async getStudentDetails({ dispatch, commit }, payload) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const { schoolId, studentId } = payload;
      const response$ = of(
        await ApiServices.getStudentDetails(schoolId, studentId)
      ).pipe(map(({ data }) => data?.data));
      response$.subscribe(member => {
        dispatch("isPageLoading", false, { root: true });
        commit("UPDATE_DETAILS", member);
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred while authenticating user",
          status: "red"
        },
        { root: true }
      );
      dispatch("isPageLoading", false, { root: true });
    }
  },
  async addNewStudent({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const { schoolId, schoolName, ...rest } = payload;
      const response$ = of(
        await ApiServices.addNewStudent(schoolId, rest)
      ).pipe(map(response => response.data));
      response$.subscribe(member => {
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message:
              member?.data?.message ?? `New student created successfully`,

            status: "success"
          },
          { root: true }
        );
        setTimeout(() => {
          Router.push({
            name: "admin.schools.students",
            params: {
              id: schoolId
            },
            query: { name: schoolName }
          });
        }, 2000);
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred while authenticating user",
          status: "red"
        },
        { root: true }
      );
      dispatch("isLoading", false, { root: true });
    }
  },
  async updateStudentDetails({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const { schoolId, _id, schoolName, body } = payload;
      const response$ = of(
        await ApiServices.updateStudent(schoolId, _id, body)
      ).pipe(map(response => response.data));
      response$.subscribe(member => {
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message:
              member?.data?.message ?? `Student details successfully updated`,

            status: "success"
          },
          { root: true }
        );
        setTimeout(() => {
          Router.push({
            name: "admin.schools.students",
            params: {
              id: schoolId
            },
            query: { name: schoolName }
          });
        }, 2000);
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred while authenticating user",
          status: "red"
        },
        { root: true }
      );
      dispatch("isLoading", false, { root: true });
    }
  },
  async deleteStudent({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const { id, schoolId } = payload;
      const response$ = of(await ApiServices.deleteStudent(schoolId, id)).pipe(
        map(response => response.data)
      );
      response$.subscribe(staffs => {
        dispatch("isLoading", false, { root: true });
        dispatch("listAllStudents", {
          id: schoolId,
          query: `?page=1&size=30`
        });
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message:
              staffs.data?.message ?? "Student details successfully deleted",
            status: "success"
          },
          { root: true }
        );
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred while deleting student",
          status: "red"
        },
        { root: true }
      );
      dispatch("isLoading", false, { root: true });
    }
  }
};

const getters = {
  getAllSchools: state => state.list,
  getPagination: state => state.paginate,
  getAllStudents: state => state.list,
  getDetails: state => state.details
};

export const schools = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
