import axios from "axios";
import router from "@/router/index";
import SecureLS from "secure-ls";
const ls = new SecureLS({
  isCompression: false
});
let HttpRequest = () => {
  let Instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 60000
  });

  Instance.interceptors.request.use(
    function(config) {
      config.headers.common["Accept"] = "application/json";
      config.headers.common["Content-Type"] = "application/json";
      const session = JSON.parse(ls.get("cloud.access"))?.auth?.user?.token;
      if (session !== null || session !== undefined) {
        config.headers.common["authorization"] = `Bearer ${session}`;
      }
      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );

  Instance.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if (error.response) {
        if (error.response.status === 401) {
          localStorage.clear();
          router.replace({ name: "account.login" });
        }
      }
      return Promise.reject(error);
    }
  );

  return Instance;
};

export default HttpRequest;
