import ApiServices from "@/services/ApiServices";
import { of } from "rxjs";
import { map } from "rxjs/operators";
import Router from "../../router/index";
const state = {
  list: [],
  details: {},
  paginate: {
    total: 0,
    page: 1,
    size: 30
  }
};

const mutations = {
  UPDATE_LIST(state, payload) {
    state.list = payload;
  },
  UPDATE_PAGINATION(state, payload) {
    state.paginate = payload;
  },
  UPDATE_DETAILS(state, payload) {
    state.details = payload;
  }
};
const actions = {
  async list({ commit, dispatch }, query) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response$ = of(await ApiServices.listAllMembers(query)).pipe(
        map(response => response.data)
      );
      response$.subscribe(members => {
        dispatch("isPageLoading", false, { root: true });
        const { docs, totalDocs, limit, page } = members.data;
        commit("UPDATE_LIST", docs);
        commit("UPDATE_PAGINATION", { total: totalDocs, size: limit, page });
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred while authenticating user",
          status: "red"
        },
        { root: true }
      );
      dispatch("isPageLoading", false, { root: true });
    }
  },
  async listAllMembersWithTrash({ commit, dispatch }, query) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response$ = of(
        await ApiServices.listAllMembersWithTrash(query)
      ).pipe(map(response => response.data));
      response$.subscribe(members => {
        dispatch("isPageLoading", false, { root: true });
        const { docs, totalDocs, limit, page } = members.data;
        commit("UPDATE_LIST", docs);
        commit("UPDATE_PAGINATION", { total: totalDocs, size: limit, page });
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred while getting list of deleted customers",
          status: "red"
        },
        { root: true }
      );
      dispatch("isPageLoading", false, { root: true });
    }
  },
  async details({ commit, dispatch }, id) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response$ = of(await ApiServices.customerDetails(id)).pipe(
        map(response => response.data)
      );
      response$.subscribe(member => {
        dispatch("isPageLoading", false, { root: true });
        commit("UPDATE_DETAILS", member.data);
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred while authenticating user",
          status: "red"
        },
        { root: true }
      );
      dispatch("isPageLoading", false, { root: true });
    }
  },
  async create({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response$ = of(
        await ApiServices.addNewMembersFroAdmin(payload)
      ).pipe(map(response => response.data));
      response$.subscribe(member => {
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: member?.data?.message ?? `New member created successfully`,

            status: "success"
          },
          { root: true }
        );
        setTimeout(() => {
          Router.push({ name: "admin.customers.all" });
        }, 2000);
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred while authenticating user",
          status: "red"
        },
        { root: true }
      );
      dispatch("isLoading", false, { root: true });
    }
  },
  async update({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const { _id, ...rest } = payload;
      const response$ = of(
        await ApiServices.updateCustomerForAdmin(_id, rest)
      ).pipe(map(response => response.data));
      response$.subscribe(member => {
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message:
              member?.data?.message ?? `Member details updated successfully`,

            status: "success"
          },
          { root: true }
        );
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred while updating user information",
          status: "red"
        },
        { root: true }
      );
      dispatch("isLoading", false, { root: true });
    }
  },
  async delete({ dispatch }, id) {
    try {
      dispatch("isLoading", true, { root: true });
      const response$ = of(await ApiServices.deleteCustomerForAdmin(id)).pipe(
        map(response => response.data)
      );
      response$.subscribe(staffs => {
        dispatch("isLoading", false, { root: true });
        dispatch("list", `?page=1&size=30`);
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message:
              staffs.data?.message ?? "Member details successfully deleted",
            status: "success"
          },
          { root: true }
        );
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred while deleting member",
          status: "red"
        },
        { root: true }
      );
      dispatch("isLoading", false, { root: true });
    }
  },
  async restore({ dispatch }, id) {
    try {
      dispatch("isLoading", true, { root: true });
      const response$ = of(
        await ApiServices.restoreDeletedCustomerForAdmin(id)
      ).pipe(map(response => response.data));
      response$.subscribe(staffs => {
        dispatch("isLoading", false, { root: true });
        dispatch("listAllMembersWithTrash", `?page=1&size=30`);
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message:
              staffs.data?.message ?? "Member details successfully restored",
            status: "success"
          },
          { root: true }
        );
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred while restoring staff member",
          status: "red"
        },
        { root: true }
      );
      dispatch("isLoading", false, { root: true });
    }
  },
  async searchList({ commit, dispatch }, query) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response$ = of(await ApiServices.searchAllMembers(query)).pipe(
        map(response => response.data)
      );
      response$.subscribe(members => {
        dispatch("isPageLoading", false, { root: true });
        const { docs, totalDocs, limit, page } = members.data;
        commit("UPDATE_LIST", docs);
        commit("UPDATE_PAGINATION", { total: totalDocs, size: limit, page });
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred while search for members",
          status: "red"
        },
        { root: true }
      );
      dispatch("isPageLoading", false, { root: true });
    }
  },
  async setPIN({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const { memberId, ...rest } = payload;
      if (rest.newPin !== rest.confirmPin) {
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: "PIN doesn't match",
            status: "red"
          },
          { root: true }
        );
        return;
      }
      const response$ = of(await ApiServices.setMemberPIN(memberId, rest)).pipe(
        map(response => response.data)
      );
      response$.subscribe(member => {
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message:
              member?.data?.message ?? `New customer PIN set successfully`,
            status: "success"
          },
          { root: true }
        );
        setTimeout(() => {
          Router.push({ name: "admin.customers.all" });
        }, 2000);
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred while setting customer PIN",
          status: "red"
        },
        { root: true }
      );
      dispatch("isLoading", false, { root: true });
    }
  }
};

const getters = {
  getAllMembers: state => state.list,
  getPagination: state => state.paginate,
  getMemberDetails: state => state.details
};

export const members = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
