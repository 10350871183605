export default [
  {
    path: "/",
    name: "root",
    redirect: "/account/login"
  },
  {
    path: "/account",
    name: "account",
    component: () => import(`@/components/greeters/LandingTemplate`),
    children: [
      {
        path: "/account/login",
        name: "account.login",
        component: () => import(`@/components/greeters/Login`),
        meta: {
          requiresAuth: false
        }
      }
    ]
  },
  //back office routes for Admin after successful login
  {
    path: "/admin",
    name: "admin",
    component: () => import(`@/components/views/layout/AppTemplate`),
    children: [
      {
        path: "/admin/home/dashboard",
        name: "admin.home.dashboard",
        component: () => import(`@/components/roles/admin/home/Dashboard`),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/admin/staffs/all",
        name: "admin.staffs.all",
        component: () => import(`@/components/roles/admin/staffs/AllStaff`),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/admin/staffs/add",
        name: "admin.staffs.add",
        component: () =>
          import(`@/components/roles/admin/staffs/AddAndEditStaffDialog`),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/admin/staffs/:id/details",
        name: "admin.staffs.details",
        component: () => import(`@/components/roles/admin/staffs/Details`),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/admin/staffs/deleted-staff",
        name: "admin.staffs.deleted-staff",
        component: () => import("@/components/roles/admin/staffs/RemovedStaff"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/admin/schools/all",
        name: "admin.schools.all",
        component: () => import(`@/components/roles/admin/schools/Schools`),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/admin/schools/:id/students",
        name: "admin.schools.students",
        component: () => import(`@/components/roles/admin/schools/Students`),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/admin/schools/:id/students/add",
        name: "admin.schools.students.add",
        component: () =>
          import(`@/components/roles/admin/schools/AddNewStudent`),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/admin/schools/:id/transactions",
        name: "admin.schools.transactions",
        component: () =>
          import(`@/components/roles/admin/schools/SchoolTransactions`),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/admin/customers/all",
        name: "admin.customers.all",
        component: () =>
          import(`@/components/roles/admin/customers/ActiveCustomers`),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/admin/customers/new",
        name: "admin.customers.new",
        component: () =>
          import(`@/components/roles/admin/customers/AddAndEditMemberPage`),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: "/admin/balances/all",
        name: "admin.balances.all",
        component: () =>
          import(`@/components/roles/admin/transactions/Balances`),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/admin/transactions/all",
        name: "admin.transactions.all",
        component: () =>
          import(`@/components/roles/admin/transactions/Transactions`),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: "/admin/transactions/exports",
        name: "admin.transactions.exports",
        component: () =>
          import(`@/components/roles/admin/transactions/Exports`),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/admin/trash/staff",
        name: "admin.trash.staff",
        component: () => import(`@/components/roles/admin/trash/Staff`),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/admin/trash/members",
        name: "admin.trash.members",
        component: () => import(`@/components/roles/admin/trash/Members`),
        meta: {
          requiresAuth: true
        }
      }
    ]
  }
];
