/* eslint-disable no-unused-vars */
import { defer, of, throwError } from "rxjs";
import { catchError, take } from "rxjs";

import { poll } from "@/operators/poll.operator";
import ApiServices from "@/services/ApiServices";
import { map } from "rxjs";
import { ajax } from "rxjs/ajax";
import SecureLS from "secure-ls";
const ls = new SecureLS({
  isCompression: false
});

let shouldError = false;
function fetchData(apiCall) {
  return defer(() => (shouldError ? throwError(null) : apiCall)).pipe(
    catchError(() => of({ response: "Fallback todo" }))
  );
}

const state = {
  transactions: [],
  members: [],
  totalCount: {
    membersCount: 0,
    staffCount: 0,
    totalMembersToday: 0,
    totalTransactions: 0
  },
  paginate: {
    total: 0,
    page: 1,
    size: 30
  }
};

const mutations = {
  UPDATE_TRANSACTIONS(state, payload) {
    state.transactions = payload;
  },
  UPDATE_MEMBERS(state, payload) {
    state.members = payload;
  },
  UPDATE_COUNT(state, payload) {
    state.totalCount = payload;
  },
  UPDATE_PAGINATION(state, payload) {
    state.paginate = payload;
  }
};

const actions = {
  async newlyAddedMembers({ dispatch, commit }, payload) {
    try {
      const { page, size } = payload;
      dispatch("isPageLoading", true, { root: true });
      const query = `?page=${page}&size=${size}&newlyAdded=true`;
      const response$ = fetchData(ApiServices.getNewlyAddedMembers(query)).pipe(
        poll(8000)
      );
      response$.subscribe(response => {
        dispatch("isPageLoading", false, { root: true });
        const { docs, totalDocs, limit, page } = response.data ?? {
          docs: [],
          totalDocs: 0,
          limit: 0,
          page: 0
        };
        commit("UPDATE_MEMBERS", docs);
        commit("UPDATE_PAGINATION", { total: totalDocs, size: limit, page });
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ??
            "An error occurred while authenticating user",
          status: "red"
        },
        { root: true }
      );
      dispatch("isPageLoading", false, { root: true });
    }
  },
  async totalCount({ dispatch, commit }) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const session = JSON.parse(ls.get("cloud.access"))?.auth?.user?.token;
      const response$ = fetchData(
        ajax({
          url: `${process.env.VUE_APP_BASE_URL}/api/v1/reports/total/list`,
          headers: {
            authorization: `Bearer ${session}`
          }
        })
      ).pipe(poll(8000));
      response$.subscribe(count => {
        dispatch("isPageLoading", false, { root: true });
        commit(
          "UPDATE_COUNT",
          count?.response?.data ?? {
            membersCount: 0,
            staffCount: 0,
            totalMembersToday: 0,
            totalTransactions: 0
          }
        );
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response?.data?.message ??
            "An error occurred while authenticating user",
          status: "red"
        },
        { root: true }
      );
      dispatch("isPageLoading", false, { root: true });
    }
  }
};

const getters = {
  getTotalCount: state => state.totalCount,
  getNewMembers: state => state.members,
  getPagination: state => state.paginate
};

export const reports = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
