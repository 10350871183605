import Vue from "vue";
import App from "./App.vue";
import VueRouterSync from "vue-router-sync";
import { sync } from "vuex-router-sync";
import "vuetify/dist/vuetify.min.css";

import store from "@/store/index";
import router from "@/router/index";
import "./assets/css/styles.css";
//import Vuetify from 'vuetify/lib'
import vuetify from "./plugins/vuetify";

import VueApexCharts from "vue-apexcharts/dist/vue-apexcharts";
Vue.component("apexcharts", VueApexCharts);

Vue.use(VueRouterSync);
sync(store, router);

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
