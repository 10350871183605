import { Observable } from "rxjs";
import Api from "./Api";

export default {
  // Auth api call
  auth(credentials) {
    return Api().post("/api/v1/auth/login", credentials);
  },
  me() {
    return Api().get(`/api/v1/profile/me`);
  },
  //Get auth token from login call
  isUserAuthenticated() {
    return (
      localStorage.auth_token != undefined || localStorage.auth_token !== null
    );
  },
  allStaffForAdmin(query) {
    return Api().get(`/api/v1/staffs${query ? query : ""}`);
  },
  staffMemberDetails(id) {
    return Api().get(`/api/v1/staffs/${id}`);
  },

  allDeletedStaffForAdmin(query) {
    return Api().get(`/api/v1/staffs/with/trash${query ? query : ""}`);
  },

  addNewStaffFroAdmin(data) {
    return Api().post("/api/v1/staffs", data);
  },

  addNewMembersFroAdmin(data) {
    return Api().post("/api/v1/members", data);
  },

  setMemberPIN(id, body) {
    return Api().post(`/api/v1/members/${id}/set/pin`, body);
  },
  listTransactionsByStaff(id, query) {
    return Api().get(`/api/v1/staffs/${id}/transactions${query ? query : ""}`);
  },

  updateStaffForAdmin(id, data) {
    return Api().put("/api/v1/staffs/" + id, data);
  },
  restoreDeletedStaffForAdmin(id) {
    return Api().put("/api/v1/staffs/with/trash/" + id + "/restore");
  },
  deleteStaffForAdmin(id) {
    return Api().delete("/api/v1/staffs/" + id);
  },
  bulkStaffUpload(data) {
    return Api().post("/api/v1/staffs/bulk/upload", data);
  },

  addNewCustomerFroAdmin(data) {
    return Api().post("/api/v1/members", data);
  },

  // customers or members
  listAllMembers(query) {
    return Api().get(`/api/v1/members${query ? query : ""}`);
  },
  searchAllMembers(query) {
    return Api().get(`/api/v1/members/search/list${query ? query : ""}`);
  },
  customerDetails(id) {
    return Api().get(`/api/v1/members/${id}`);
  },
  updateCustomerForAdmin(id, rest) {
    return Api().put(`/api/v1/members/${id}`, rest);
  },
  listAllMembersWithTrash(query) {
    return Api().get(`/api/v1/members/with/trash${query ? query : ""}`);
  },
  deleteCustomerForAdmin(id) {
    return Api().delete(`/api/v1/members/${id}`);
  },
  restoreDeletedCustomerForAdmin(id) {
    return Api().put(`/api/v1/members/with/trash/${id}/restore`);
  },

  //transactions
  listAllTransactions(query) {
    return Api().get(`/api/v1/transactions${query ? query : ""}`);
  },
  downloadTransactions(query) {
    return Api().get(`/api/v1/transactions/download${query ? query : ""}`, {
      responseType: "blob"
    });
  },
  searchAllTransactions(query) {
    return Api().get(`/api/v1/transactions/search/list${query ? query : ""}`);
  },

  getNewlyAddedMembers(query) {
    return new Observable(observer => {
      Api()
        .get(`/api/v1/members${query ? query : ""}`)
        .then(response => {
          observer.next(response.data);
          observer.complete();
        })
        .catch(error => {
          observer.error(error);
        });
    });
  },

  //balances
  async listAllBalances(query) {
    return Api().get(`/api/v1/balances${query ? query : ""}`);
  },
  async uploadBalances(payload) {
    return Api().post(`/api/v1/organizations/bulk/upload/balances`, payload);
  },
  async searchAllBalances(query) {
    return Api().get(`/api/v1/balances/search/list${query ? query : ""}`);
  },

  getTotalCount() {
    return Api().get(`/api/v1/reports/total/list`);
  },

  // schools
  async listAllSchools(query) {
    return Api().get(`/api/v1/organizations/schools/all${query ? query : ""}`);
  },
  async listAllStudents(id, query) {
    return Api().get(
      `/api/v1/organizations/schools/${id}/students${query ? query : ""}`
    );
  },
  async getStudentDetails(schoolId, id) {
    return Api().get(
      `/api/v1/organizations/schools/${schoolId}/students/${id}`
    );
  },
  async addNewStudent(schoolId, payload) {
    return Api().post(
      `/api/v1/organizations/schools/${schoolId}/students`,
      payload
    );
  },
  async updateStudent(schoolId, studentId, payload) {
    return Api().patch(
      `/api/v1/organizations/schools/${schoolId}/students/${studentId}`,
      payload
    );
  },
  async deleteStudent(schoolId, id) {
    return Api().delete(
      `/api/v1/organizations/schools/${schoolId}/students/${id}`
    );
  }
};
